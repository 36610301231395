import { HiChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { translate } from "ui/i18n";
import { HorizontalDivider } from "ui/pages/app/components/HorizontalDivider";

export function AboutYou() {
  const navigate = useNavigate();

  return (
    <div className="w-full rounded-[20px] bg-white">
      <button
        className="flex w-full items-center justify-between p-4 py-6 md:p-6"
        onClick={() => navigate("/change-personal-infos")}
      >
        <span className="text-md font-medium text-secondaryGrey-800">
          {translate("ns1:Account.PersonalInfos")}
        </span>
        <HiChevronRight size={24} color="#485585" />
      </button>
      <HorizontalDivider />
      <button className="flex w-full items-center justify-between p-4 py-6 md:p-6">
        <span className="text-md font-medium text-secondaryGrey-800">
          {translate("ns1:Account.Email")}
        </span>
        <HiChevronRight size={24} color="#485585" />
      </button>
      <HorizontalDivider />
      <button
        className="flex w-full items-center justify-between  p-4 py-6 md:p-6"
        onClick={() => navigate("/change-password")}
      >
        <span className="text-md font-medium text-secondaryGrey-800">
          {translate("ns1:Account.Password")}
        </span>
        <HiChevronRight size={24} color="#485585" />
      </button>
    </div>
  );
}

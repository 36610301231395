import { useDisclosure } from "@chakra-ui/hooks";
import { Seance } from "domain/entities/seances.entities";
import { deleteSeance, duplicateSeance } from "domain/seances/seances.actions";
import {
  HiHeart,
  HiOutlineDuplicate,
  HiOutlineHeart,
  HiOutlineTrash,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { BannerGrey300 } from "ui/assets/svg/seance-banners-desktop/BannerGrey300";
import { BannerLightGreen500 } from "ui/assets/svg/seance-banners-desktop/BannerLightGreen500";
import { BannerPaleGreen200 } from "ui/assets/svg/seance-banners-desktop/BannerPaleGreen200";
import { BannerPaleGreen400 } from "ui/assets/svg/seance-banners-desktop/BannerPaleGreen400";
import { BannerPink200 } from "ui/assets/svg/seance-banners-desktop/BannerPink200";
import { BannerPink300 } from "ui/assets/svg/seance-banners-desktop/BannerPink300";
import { BannerPink400 } from "ui/assets/svg/seance-banners-desktop/BannerPink400";
import { BannerPink500 } from "ui/assets/svg/seance-banners-desktop/BannerPink500";
import { CardBgGrey300 } from "ui/assets/svg/seance-card-backgrounds/CardBgGrey300";
import { CardBgLightGreen500 } from "ui/assets/svg/seance-card-backgrounds/CardBgLightGreen500";
import { CardBgPaleGreen200 } from "ui/assets/svg/seance-card-backgrounds/CardBgPaleGreen200";
import { CardBgPaleGreen400 } from "ui/assets/svg/seance-card-backgrounds/CardBgPaleGreen400";
import { CardBgPink200 } from "ui/assets/svg/seance-card-backgrounds/CardBgPink200";
import { CardBgPink300 } from "ui/assets/svg/seance-card-backgrounds/CardBgPink300";
import { CardBgPink400 } from "ui/assets/svg/seance-card-backgrounds/CardBgPink400";
import { CardBgPink500 } from "ui/assets/svg/seance-card-backgrounds/CardBgPink500";
import { useAppDispatch } from "ui/hook/store";
import { translate } from "ui/i18n";
import { Snackbar } from "ui/pages/snackbar";
import { useWindowWidth } from "ui/utils/hooks";
import { ModalDeleteRessource } from "ui/pages/app/components/ModalDeleteRessource";

export function SeanceBanner({
  seance,
  isFavorite,
  handleFavoriteClick,
}: {
  seance: Seance;
  isFavorite?: boolean;
  handleFavoriteClick?: (e: any) => Promise<void>;
}) {
  const isDesktop = useWindowWidth();
  const bannerInfos = getBannerImage(Number(seance.duration), isDesktop);
  const dispatch = useAppDispatch();
  const modalDeleteState = useDisclosure();
  const navigate = useNavigate();

  const handleDuplication = async () => {
    const result = await dispatch(duplicateSeance(seance.id)).unwrap();
    if (result.success) {
      Snackbar.showSuccess({
        title: translate("ns1:Seance.Duplicated"),
      });
    }
  };

  const handleDeleteSeance = async () => {
    const result = await dispatch(deleteSeance(seance.id)).unwrap();
    if (result.success) {
      Snackbar.showSuccess({
        title: translate("ns1:Activities.RessourceDeleted"),
      });
      navigate("/my-creations");
    }
  };

  return (
    <div className="activity-banner relative w-full">
      <bannerInfos.image width="100%" height="100%" />
      {isDesktop && (
        <div className="absolute right-6 top-6 hidden space-x-4 md:flex">
          <button
            className="flex h-10 w-10 items-center justify-center rounded-xl bg-white"
            onClick={handleFavoriteClick}
          >
            {isFavorite ? (
              <HiHeart className="text-secondaryGrey-900" size={20} />
            ) : (
              <HiOutlineHeart className="text-secondaryGrey-900" size={20} />
            )}
          </button>
          {seance?.userId && (
            <button
              className="flex h-10 w-10 items-center justify-center rounded-xl bg-white"
              onClick={handleDuplication}
            >
              <HiOutlineDuplicate
                className="text-secondaryGrey-900"
                size={20}
              />
            </button>
          )}
          {/* <button className="flex h-10 w-10 items-center justify-center rounded-xl bg-white">
            <HiDownload className="text-secondaryGrey-900" size={20} />
          </button> */}
          {seance?.userId && (
            <button
              className="flex h-10 w-10 items-center justify-center rounded-xl bg-white"
              onClick={() => modalDeleteState.onOpen()}
            >
              <HiOutlineTrash className="text-secondaryGrey-900" size={20} />
            </button>
          )}
        </div>
      )}
      <div
        className={`items-bottom absolute bottom-6 flex w-full space-x-6 px-6 text-2xl ${bannerInfos.color}`}
      >
        <span className="font-bold">{seance.title}</span>
        {/* <span className="flex items-end">
          <HiThumbUp size={28} className="me-2" />
          <span className=" font-medium">12</span>
        </span> */}
      </div>

      <ModalDeleteRessource
        isOpen={modalDeleteState.isOpen}
        onClose={modalDeleteState.onClose}
        deleteRessource={handleDeleteSeance}
      />
    </div>
  );
}

function getBannerImage(duration: number, isDesktop: boolean) {
  if (duration <= 70)
    return {
      image: isDesktop ? BannerGrey300 : CardBgGrey300,
      color: "text-white",
    };
  else if (duration <= 90)
    return {
      image: isDesktop ? BannerPaleGreen200 : CardBgPaleGreen200,
      color: "text-secondaryGrey-900",
    };
  else if (duration <= 120)
    return {
      image: isDesktop ? BannerLightGreen500 : CardBgLightGreen500,
      color: "text-secondaryGrey-900",
    };
  else if (duration <= 150)
    return {
      image: isDesktop ? BannerPaleGreen400 : CardBgPaleGreen400,
      color: "text-white",
    };
  else if (duration <= 180)
    return {
      image: isDesktop ? BannerPink200 : CardBgPink200,
      color: "text-secondaryGrey-900",
    };
  else if (duration <= 210)
    return {
      image: isDesktop ? BannerPink300 : CardBgPink300,
      color: "text-secondaryGrey-900",
    };
  else if (duration <= 360)
    return {
      image: isDesktop ? BannerPink400 : CardBgPink400,
      color: "text-white",
    };
  else
    return {
      image: isDesktop ? BannerPink500 : CardBgPink500,
      color: "text-white",
    };
}

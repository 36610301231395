import toast, { Toast } from "react-hot-toast";
import { HiLockClosed, HiOutlineX } from "react-icons/hi";
import { translate } from "ui/i18n";
import { PrimaryButton } from "../app/components/PrimaryButton";

interface IProps {
  t: Toast;
  onClickNavigate: () => void;
  type: "seance" | "technique" | "create-seance";
}

export function SubscriptionSnackbar({ t, onClickNavigate, type }: IProps) {
  const handleSubClick = () => {
    onClickNavigate();
    toast.remove(t.id);
  };

  return (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } flex w-fit max-w-sm rounded-2xl bg-white shadow-lg`}
    >
      <div className="flex flex-1 items-start p-4">
        <div className="flex h-14 w-14 items-center justify-center rounded-full bg-darkGrey-100">
          <HiLockClosed size={25} className="text-secondaryGrey-900" />
        </div>
        <div className="ml-3 flex-1">
          {type === "seance" && (
            <p className="text-base font-bold text-secondaryGrey-900">
              {translate("ns1:Snackbar.DiscoverSeances")}
            </p>
          )}
          {type === "create-seance" && (
            <p className="text-base font-bold text-secondaryGrey-900">
              {translate("ns1:Snackbar.CreateRessource")}
            </p>
          )}
          <p className="mt-1 text-sm font-medium text-secondaryGrey-700">
            {translate("ns1:Snackbar.TakeAdvantage")}
          </p>
          <PrimaryButton
            height="36px"
            className="mt-3"
            onClick={handleSubClick}
          >
            {translate("ns1:Snackbar.LearnMore")}
          </PrimaryButton>
        </div>
      </div>
      <div className="p-3">
        <button onClick={() => toast.remove(t.id)} className="">
          <HiOutlineX size={20} className="text-secondaryGrey-900" />
        </button>
      </div>
    </div>
  );
}

import { Technique, TechniqueType } from "domain/entities/techniques.entities";
import {
  HiCheck,
  HiHeart,
  HiOutlineHeart,
  HiOutlineTrash,
  HiPlus,
  HiThumbUp,
} from "react-icons/hi";
import { CardBgGrey400 } from "ui/assets/svg/technique-card-backgrounds/CardBgGrey400";
import { CardBgLightGreen400 } from "ui/assets/svg/technique-card-backgrounds/CardBgLightGreen400";
import { CardBgLightGreen500 } from "ui/assets/svg/technique-card-backgrounds/CardBgLightGreen500";
import { CardBgPaleGreen200 } from "ui/assets/svg/technique-card-backgrounds/CardBgPaleGreen200";
import { CardBgPaleGreen400 } from "ui/assets/svg/technique-card-backgrounds/CardBgPaleGreen400";
import { CardBgPink200 } from "ui/assets/svg/technique-card-backgrounds/CardBgPink200";
import { CardBgPink300 } from "ui/assets/svg/technique-card-backgrounds/CardBgPink300";
import { CardBgPink400 } from "ui/assets/svg/technique-card-backgrounds/CardBgPink400";
import { CardBgPink500 } from "ui/assets/svg/technique-card-backgrounds/CardBgPink500";
import { CardBgGrey400 as CardBgGrey400Square } from "ui/assets/svg/technique-card-bg-square/CardBgGrey400";
import { CardBgLightGreen400 as CardBgLightGreen400Square } from "ui/assets/svg/technique-card-bg-square/CardBgLightGreen400";
import { CardBgLightGreen500 as CardBgLightGreen500Square } from "ui/assets/svg/technique-card-bg-square/CardBgLightGreen500";
import { CardBgPaleGreen200 as CardBgPaleGreen200Square } from "ui/assets/svg/technique-card-bg-square/CardBgPaleGreen200";
import { CardBgPaleGreen400 as CardBgPaleGreen400Square } from "ui/assets/svg/technique-card-bg-square/CardBgPaleGreen400";
import { CardBgPink200 as CardBgPink200Square } from "ui/assets/svg/technique-card-bg-square/CardBgPink200";
import { CardBgPink300 as CardBgPink300Square } from "ui/assets/svg/technique-card-bg-square/CardBgPink300";
import { CardBgPink400 as CardBgPink400Square } from "ui/assets/svg/technique-card-bg-square/CardBgPink400";
import { CardBgPink500 as CardBgPink500Square } from "ui/assets/svg/technique-card-bg-square/CardBgPink500";
import { translate } from "ui/i18n";
import { getDurationInHours } from "ui/utils/formatters";
import { useWindowWidth } from "ui/utils/hooks";
import { MouseEvent } from "react";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { selectFavoriteTechniques } from "domain/activities/activities.selectors";
import { toggleTechniqueFavorite } from "ui/utils/toggle-activity-favorite";

interface IProps {
  technique: Technique;
  onClick?: () => void;
  selection?: boolean;
  onSelect?: () => void;
  selected?: boolean;
  inSelectionZone?: boolean;
  row?: boolean;
  favButton?: boolean;
}

export function TechniqueCard({
  technique,
  onClick,
  selection,
  onSelect,
  selected,
  inSelectionZone,
  row,
  favButton = true,
}: IProps) {
  const CardImage = getImage(technique.type as TechniqueType, row);
  const isDesktop = useWindowWidth();
  const dispatch = useAppDispatch();
  const favoriteTechnique = useAppSelector(selectFavoriteTechniques).find(
    (t) => t.techniqueId === technique?.id
  );

  const handleCardClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  const handleSelectionClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (onSelect) onSelect();
  };

  const handleFavoriteClick = async (e: MouseEvent) => {
    e.stopPropagation();
    toggleTechniqueFavorite({
      favoriteActivity: favoriteTechnique,
      dispatch,
      activityId: technique.id,
    });
  };

  return (
    <>
      {!row && (
        <div
          className={`flex h-full flex-col justify-between rounded-[20px] bg-white p-3 md:p-5 ${
            onClick ? "cursor-pointer" : ""
          }`}
          onClick={handleCardClick}
        >
          <div>
            <div className="relative">
              <CardImage width="100%" height="100%" />
              {favButton && (
                <button
                  className="absolute right-2 top-2 flex h-8 w-8 items-center justify-center rounded-full bg-white md:right-4 md:top-4"
                  onClick={handleFavoriteClick}
                >
                  {!!favoriteTechnique?.id ? (
                    <HiHeart className="text-secondaryGrey-900" size={18} />
                  ) : (
                    <HiOutlineHeart
                      className="text-secondaryGrey-900"
                      size={18}
                    />
                  )}
                </button>
              )}
            </div>

            <div className="mt-4 flex items-center text-sm font-medium">
              <HiThumbUp size={16} className="me-1" />
              12
            </div>

            <span className="mt-2 block text-base font-bold">
              {technique.title}
            </span>
          </div>

          <div className="flex items-end justify-between">
            <div className="mt-3 flex space-x-2 md:mt-8">
              {(isDesktop || selection) && (
                <div className="rounded-[36px] bg-darkGrey-200 px-3 py-1 text-xs font-semibold">
                  {getDurationInHours(Number(technique.duration))}
                </div>
              )}
              {!selection && (
                <div className="rounded-[36px] bg-darkGrey-200 px-3 py-1 text-xs font-semibold">
                  {translate(
                    `ns1:Techniques.${technique.type as TechniqueType}`
                  )}
                </div>
              )}
            </div>
            {selection && (
              <>
                {selected ? (
                  <button
                    onClick={(e) => handleSelectionClick(e)}
                    className="flex h-8 w-8 items-center justify-center rounded-xl bg-darkGrey-100 md:h-11 md:w-11"
                  >
                    {inSelectionZone ? (
                      <HiOutlineTrash
                        size={isDesktop ? 24 : 20}
                        color="#485585"
                      />
                    ) : (
                      <HiCheck size={isDesktop ? 24 : 20} color="#485585" />
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => handleSelectionClick(e)}
                    className="flex h-8 w-8 items-center justify-center rounded-xl bg-darkGrey-800 md:h-11 md:w-11"
                  >
                    <HiPlus size={isDesktop ? 24 : 20} color="#fff" />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {row && (
        <div
          className={`flex h-full w-full flex-row items-center justify-between space-x-4 rounded-[16px] bg-white p-3 md:p-5 ${
            onClick ? "cursor-pointer" : ""
          }`}
          onClick={handleCardClick}
        >
          <div className="flex flex-row items-center space-x-4">
            <div style={{ width: "64px", height: "64px" }}>
              <CardImage width="64px" height="64px" />
            </div>
            <div className="flex flex-col space-y-2">
              <p className="text-sm font-bold text-secondaryGrey-900">
                {technique.title}
              </p>
              <div className="w-fit rounded-[36px] bg-darkGrey-200 px-3 py-1 text-xs font-semibold">
                {getDurationInHours(Number(technique.duration))}
              </div>
            </div>
          </div>
          <div className="h-9 w-9">
            <button
              onClick={(e) => handleSelectionClick(e)}
              className="flex h-9 w-9 items-center justify-center rounded-xl bg-darkGrey-100"
            >
              <HiOutlineTrash size={isDesktop ? 24 : 20} color="#485585" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

function getImage(activityType: TechniqueType, row?: boolean) {
  switch (activityType) {
    case "ice_breaker":
      return row ? CardBgLightGreen500Square : CardBgLightGreen500;
    case "problematique":
      return row ? CardBgPink500Square : CardBgPink500;
    case "equipes":
      return row ? CardBgPaleGreen400Square : CardBgPaleGreen400;
    case "brainstorming":
      return row ? CardBgPink300Square : CardBgPink300;
    case "projects":
      return row ? CardBgPaleGreen200Square : CardBgPaleGreen200;
    case "pratiques":
      return row ? CardBgPink200Square : CardBgPink200;
    case "evaluation":
      return row ? CardBgPink400Square : CardBgPink400;
    case "pause":
      return row ? CardBgLightGreen400Square : CardBgLightGreen400;
    case "vote":
      return row ? CardBgGrey400Square : CardBgGrey400;
    default:
      return row ? CardBgLightGreen500Square : CardBgLightGreen500;
  }
}

import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";
import { BackButton } from "ui/pages/app/components/BackButton";
import { translate } from "ui/i18n";
import { CreateRessource } from "ui/assets/svg/CreateRessource";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { useWindowWidth } from "ui/utils/hooks";
import { HiDuplicate, HiPencilAlt } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ModalCreateRessource({ isOpen, onClose }: IProps) {
  const isDesktop = useWindowWidth();
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      {/* size="full" */}
      <ModalOverlay />
      <ModalContent maxW="750px" margin="20px" className="!rounded-[30px] p-8">
        <BackButton onClick={onClose} />
        <div className="mt-12 flex flex-col items-center">
          <h4 className="w-fit text-center font-bold  text-secondaryGrey-900">
            {translate("ns1:CreateSeance.WhatDoYouWantCreate")}
          </h4>

          <div className="mt-16" style={{ maxWidth: 500, width: "100%" }}>
            <CreateRessource />
          </div>
        </div>

        <div className="mt-16">
          <PrimaryButton
            type="submit"
            height={isDesktop ? "52px" : "46px"}
            width="100%"
            className="disabled bg-pink-400 hover:bg-pink-300 disabled:bg-pink-300 disabled:bg-opacity-20"
            disabled
          >
            <HiPencilAlt color="#fff" size={24} className="me-2" />
            {translate("ns1:CreateSeance.CreateATechnique")}
          </PrimaryButton>

          <PrimaryButton
            height={isDesktop ? "52px" : "46px"}
            width="100%"
            className="mt-4 md:mt-6"
            onClick={() => navigate("/create-seance")}
          >
            <HiDuplicate color="#fff" size={24} className="me-2" />
            {translate("ns1:CreateSeance.CreateASeance")}
          </PrimaryButton>
        </div>
      </ModalContent>
    </Modal>
  );
}

import { TechniquesFilters } from "domain/entities/techniques.entities";
import {
  DurationFilter,
  ActivitiesPageFilters,
} from "domain/entities/activities.entities";

export const getActivitiesFilters = (
  filters: TechniquesFilters &
    Partial<ActivitiesPageFilters> &
    Partial<{ duration: DurationFilter; search: string }>
) => {
  const participants = getParticipants(filters.participants);
  const duration = getDuration(filters.selectedDuration);

  return {
    "context[$in]": filters.contexts,
    "type[$in]": filters.types,
    ...(filters.modalities?.includes("remote") && { remote: true }),
    ...(filters.modalities?.includes("onsite") && { onsite: true }),
    ...(filters.duration && { duration: filters.duration }),
    ...(filters.selectedDuration?.length > 0 && duration),
    "$sort[createdAt]": filters["$sort[createdAt]"] || -1,
    ...(filters.participants?.length > 0 && participants),
    ...(typeof filters.isInniz === "boolean" && { isInniz: filters.isInniz }),
    ...(filters.pauses === false && { "type[$nin]": ["pause"] }),
    ...(filters.search?.length > 0 && {
      "title[$ilike]": `%${filters.search}%`,
    }),
    "objectifFilter[$in]": filters.objectives,
  };
};

const getParticipants = (participants: string) => {
  if (participants) {
    if (participants.includes("lte")) {
      return { "participants[$lte]": participants.replace("lte", "") };
    } else {
      return { "participants[$gte]": participants.replace("gte", "") };
    }
  } else return null;
};

const getDuration = (duration: string) => {
  if (duration) {
    if (duration === "lte120") {
      return { "duration[$lte]": 120 };
    } else if (duration === "lte240") {
      return { "duration[$gte]": 120, "duration[$lte]": 240 };
    } else if (duration === "lte1440") {
      return { "duration[$gte]": 240, "duration[$lte]": 1440 };
    } else if (duration === "gte1440") {
      return { "duration[$gte]": 1440 };
    }
  } else return null;
};

import { createSlice } from "@reduxjs/toolkit";
import { ActivityAdvice } from "domain/entities/activities.entities";
import { Technique } from "domain/entities/techniques.entities";
import {
  getTechniques,
  getHomeTechniques,
  getTechnique,
  getTechniqueAdvice,
  getFavoritesTechniques,
} from "domain/techniques/techniques.actions";

export type TechniquesState = {
  isTechniquesLoading: boolean;
  homeIntroTechniques: Technique[];
  homeCoreTechniques: Technique[];
  homeConclusionTechniques: Technique[];
  techniques: Technique[];
  isTechniqueLoading: boolean;
  technique: Technique;
  advice: ActivityAdvice | null;
};

const initialState: TechniquesState = {
  isTechniquesLoading: false,
  homeIntroTechniques: [],
  homeCoreTechniques: [],
  homeConclusionTechniques: [],
  techniques: [],
  isTechniqueLoading: false,
  technique: null,
  advice: null,
};

export const techniquesSlice = createSlice({
  name: "techniques",
  initialState,
  reducers: {},
  extraReducers(builder) {
    //GET HOME TECHNIQUES
    builder.addCase(getHomeTechniques.fulfilled, (state, action) => {
      if (action.payload.context === "INTRO") {
        state.homeIntroTechniques = action.payload.techniques;
      } else if (action.payload.context === "CORE") {
        state.homeCoreTechniques = action.payload.techniques;
      } else if (action.payload.context === "CONCLUSION") {
        state.homeConclusionTechniques = action.payload.techniques;
      }
      state.isTechniquesLoading = false;
    });
    builder.addCase(getHomeTechniques.pending, (state, action) => {
      state.isTechniquesLoading = true;
    });
    //GET TECHNIQUES
    builder.addCase(getTechniques.fulfilled, (state, action) => {
      state.techniques = action.payload.techniques;
      state.isTechniquesLoading = false;
    });
    builder.addCase(getTechniques.pending, (state, action) => {
      state.isTechniquesLoading = true;
    });
    //GET TECHNIQUE
    builder.addCase(getTechnique.fulfilled, (state, action) => {
      state.technique = action.payload.technique;
      state.isTechniqueLoading = false;
    });
    builder.addCase(getTechnique.pending, (state, action) => {
      state.isTechniqueLoading = true;
    });
    //GET ADVICE
    builder.addCase(getTechniqueAdvice.fulfilled, (state, action) => {
      state.advice = action.payload.advice;
      state.isTechniqueLoading = false;
    });
    builder.addCase(getTechniqueAdvice.pending, (state, action) => {
      state.isTechniqueLoading = true;
    });
    //GET FAVORITES TECHNIQUES
    builder.addCase(getFavoritesTechniques.fulfilled, (state, action) => {
      state.techniques = action.payload.techniques;
      state.isTechniqueLoading = false;
    });
    builder.addCase(getFavoritesTechniques.pending, (state, action) => {
      state.isTechniqueLoading = true;
    });
  },
});

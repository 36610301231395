import { PortailSession } from "domain/entities/auth.entities";
import { z } from "zod";

export const getUserInfosDtoSchema = z.object({
  id: z.number(),
  email: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  jobRole: z.string().nullable(),
  newsletter: z.boolean().nullable(),
  isVerified: z.boolean(),
  isPremium: z.boolean(),
});

export const loginDtoSchema = z.object({
  accessToken: z.string(),
  user: z.object({
    email: z.string(),
    firstName: z.string().nullable(),
    id: z.number(),
    isVerified: z.boolean(),
    jobRole: z.string().nullable(),
    lastName: z.string().nullable(),
    newsletter: z.boolean().nullable(),
  }),
});

export const portalSessionDtoSchema = z.custom<PortailSession>();

import { getFavoriteActivities } from "domain/activities/activities.actions";
import { getPortalSession } from "domain/auth/auth.actions";
import { getCarouselSlides } from "domain/carousel/carousel.actions";
import {
  getFavoritesSeances,
  getSeance,
  getSeanceAdvice,
  getSeanceMateriels,
  getSeances,
  removeSeanceAdvice,
} from "domain/seances/seances.actions";
import { store } from "domain/store";
import {
  getFavoritesTechniques,
  getHomeTechniques,
  getTechnique,
  getTechniqueAdvice,
} from "domain/techniques/techniques.actions";
import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { prepareAuth } from "ui/navigation/loaders/prepareAuth";

export async function homeLoader() {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  store.dispatch(
    getHomeTechniques({
      context: "INTRO",
      $limit: 10,
      "$sort[createdAt]": -1,
      isInniz: true,
    })
  );
  store.dispatch(
    getHomeTechniques({
      context: "CORE",
      $limit: 10,
      "$sort[createdAt]": -1,
      isInniz: true,
    })
  );
  store.dispatch(
    getHomeTechniques({
      context: "CONCLUSION",
      $limit: 10,
      "$sort[createdAt]": -1,
      isInniz: true,
    })
  );
  store.dispatch(getCarouselSlides());
  store.dispatch(getFavoriteActivities());

  return null;
}

export async function techniquesLoader() {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  store.dispatch(getFavoriteActivities());

  return null;
}

export async function seancesLoader() {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  store.dispatch(getFavoriteActivities());

  return null;
}

export async function techniqueLoader({ params }: LoaderFunctionArgs) {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  const { success } = await store
    .dispatch(getTechnique(Number(params.id)))
    .unwrap();

  if (!success) window.location.href = "/";

  store.dispatch(getTechniqueAdvice(Number(params.id)));
  store.dispatch(getFavoriteActivities());

  return null;
}

export async function seanceLoader({ params }: LoaderFunctionArgs) {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  const { seance, success } = await store
    .dispatch(getSeance(Number(params.id)))
    .unwrap();

  if (!success) window.location.href = "/";

  //if the seance is not created by the user, get advice, else remove advice
  if (!seance.userId) {
    store.dispatch(getSeanceAdvice(Number(params.id)));
  } else store.dispatch(removeSeanceAdvice());

  store.dispatch(getSeanceMateriels(Number(params.id)));
  store.dispatch(getFavoriteActivities());

  return null;
}

export async function createSeanceLoader() {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  return null;
}

export async function myCreationsLoader() {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  store.dispatch(getSeances({ isInniz: false, participants: "" }));
  store.dispatch(getFavoriteActivities());

  return null;
}

export async function accountLoader() {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  if (store.getState().auth.userInfos?.isPremium) {
    store.dispatch(getPortalSession());
  }

  return null;
}

export async function favoritesLoader() {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  const result = await store.dispatch(getFavoriteActivities()).unwrap();
  if (result.success) {
    const techniqueIds: number[] = [];
    const seanceIds: number[] = [];
    result.activities.forEach((a) => {
      if (a.type === "technique") techniqueIds.push(a.techniqueId);
      else if (a.type === "seance") seanceIds.push(a.seanceId);
    });
    store.dispatch(getFavoritesTechniques(techniqueIds));
    store.dispatch(getFavoritesSeances(seanceIds));
  }
  return null;
}

export async function changePasswordLoader() {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  return null;
}

export async function changePersonalInfosLoader() {
  const { userIsConnected } = await prepareAuth();
  if (!userIsConnected) {
    return redirect("/auth");
  }

  return null;
}

import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalContent } from "@chakra-ui/modal";
import { selectUserName } from "domain/auth/auth.selectors";
import { ActivityAdvice } from "domain/entities/activities.entities";
import {
  Technique,
  TechniqueContext,
  TechniqueType,
} from "domain/entities/techniques.entities";
import { getTechniqueAdvice } from "domain/techniques/techniques.actions";
import { SetStateAction, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { HiArrowLeft, HiCheck } from "react-icons/hi";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import { Advice } from "ui/pages/app/components/activities/Advice";
import { Materiels } from "ui/pages/app/components/activities/Materiels";
import { Opinions } from "ui/pages/app/components/activities/Opinions";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { SecondaryButton } from "ui/pages/app/components/SecondaryButton";
import { SequenceComponent } from "ui/pages/app/techniques/technique/SequenceComponent";
import { TechniqueBanner } from "ui/pages/app/techniques/technique/TechniqueBanner";
import { getDurationInHours } from "ui/utils/formatters";
import { useWindowWidth } from "ui/utils/hooks";

interface IProps {
  isOpen: boolean;
  technique: Technique;
  closeViewTechniqueModal: () => void;
  selected?: boolean;
  selectedTechniques?: Technique[];
  setSelectedTechniques?: (value: SetStateAction<Technique[]>) => void;
  updateSelectedTechniques?: (
    technique: Technique,
    action: "add" | "remove"
  ) => void;
}

export function ModalTechnique({
  isOpen,
  technique,
  closeViewTechniqueModal,
  selected,
  selectedTechniques,
  setSelectedTechniques,
  updateSelectedTechniques,
}: IProps) {
  const isDesktop = useWindowWidth();
  const dispatch = useAppDispatch();
  const seeMoreOpinions = useDisclosure();
  const [advice, setAdvice] = useState<ActivityAdvice | null>(null);
  const userName = useAppSelector(selectUserName);

  useEffect(() => {
    fetchAdvice();
  }, [technique]); //eslint-disable-line

  const fetchAdvice = async () => {
    if (technique?.id && technique.type !== "CUSTOM") {
      const result = await dispatch(
        getTechniqueAdvice(Number(technique.id))
      ).unwrap();
      if (result.success) {
        setAdvice(result.advice);
      } else setAdvice(null);
    }
  };

  const handleTechniqueSelection = () => {
    if (selected) {
      setSelectedTechniques([
        ...selectedTechniques.filter((t) => t.id !== technique.id),
      ]);
    } else {
      updateSelectedTechniques(technique, "add");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeViewTechniqueModal} size="full">
      <ModalContent>
        <div className="flex min-h-dvh flex-col bg-darkGrey-100 p-4 md:flex-row md:py-8 md:pl-6 md:pr-10">
          <div className="my-4 flex w-full items-center justify-between md:my-0 md:w-12 md:items-start">
            <button
              onClick={closeViewTechniqueModal}
              className="z-50 flex h-5 items-center"
            >
              <HiArrowLeft size={24} className="text-darkGrey-800" />
            </button>
            {!isDesktop && (
              <div className="flex w-fit space-x-4">
                <div className="flex h-8 w-8 items-center justify-center rounded-full border border-darkGrey-900 bg-darkGrey-700">
                  <HiCheck color="#fff" size={26} />
                </div>
              </div>
            )}
          </div>

          {technique ? (
            <>
              <div className="flex w-full flex-col space-y-6 md:space-y-8">
                <TechniqueBanner technique={technique} checkIcon />

                <div className="flex flex-wrap gap-x-3 gap-y-3 md:justify-start">
                  {technique.type === "CUSTOM" && (
                    <div className="flex flex h-7 w-7 items-center items-center justify-center rounded-full bg-darkGrey-700 text-xs font-semibold text-white">
                      {userName?.charAt(0)}
                    </div>
                  )}
                  <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-700 px-3 text-xs font-semibold text-white">
                    {translate(
                      `ns1:Techniques.${technique.type as TechniqueType}`
                    )}
                  </div>
                  <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-200 px-3 text-xs font-semibold text-darkGrey-700">
                    {translate(
                      `ns1:Techniques.${technique.context as TechniqueContext}`
                    )}
                  </div>
                  {technique.duration && Number(technique.duration) !== 0 && (
                    <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-200 px-3 text-xs font-semibold text-darkGrey-700">
                      {getDurationInHours(Number(technique.duration))}
                    </div>
                  )}
                  {(technique.onsite || technique.remote) && (
                    <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-200 px-3 text-xs font-semibold text-darkGrey-700">
                      {technique.onsite &&
                        technique.remote &&
                        translate("ns1:Activities.remote&onsite")}
                      {technique.onsite &&
                        !technique.remote &&
                        translate("ns1:Activities.onsite")}
                      {!technique.onsite &&
                        technique.remote &&
                        translate("ns1:Activities.remote")}
                    </div>
                  )}
                  {technique.participants > 0 && (
                    <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-200 px-3 text-xs font-semibold text-darkGrey-700">
                      <Trans
                        i18nKey={
                          "Activities.UntilParticipants" as
                            | never[]
                            | (string & never[])
                        }
                        values={{ participants: technique.participants }}
                      />
                    </div>
                  )}
                </div>

                <div className="flex flex-col space-x-0 space-y-6 md:flex-row md:space-x-8 md:space-y-0">
                  <div className="flex w-full flex-col space-y-6 md:space-y-8">
                    <div className="rounded-[30px] bg-white p-4 py-6 md:p-6">
                      <span className="text-base font-medium md:text-sm">
                        {`${translate("ns1:Activities.Goal")}: ${
                          technique.objectif
                        }`}
                      </span>
                      {technique.sequences.length > 0 &&
                        technique.sequences.map((sequence, index) => (
                          <SequenceComponent
                            key={"sequence" + index}
                            sequence={sequence}
                            index={index}
                            sequencesLength={technique.sequences.length}
                          />
                        ))}
                    </div>

                    {/* advice and equipment for mobile */}
                    {!isDesktop &&
                      (advice || technique.materiels.length > 0) && (
                        <div className="flex flex-col space-y-6 md:space-y-8">
                          {technique.materiels.length > 0 &&
                            technique.materiels[0].id && (
                              <Materiels materiels={technique.materiels} />
                            )}
                          {advice && <Advice advice={advice} />}
                        </div>
                      )}

                    {technique.type !== "CUSTOM" && (
                      <div className="rounded-[30px] bg-white p-4 py-6 md:p-6">
                        <div className="flex flex-col items-center justify-center space-y-1.5">
                          {technique.testimonials?.length > 0 ? (
                            <>
                              <p className="text-3xl font-bold text-secondaryGrey-900">
                                {
                                  technique.testimonials.filter(
                                    (t) => t.type === "APPROVEDBY"
                                  ).length
                                }
                              </p>
                              <p className="text-xl font-bold text-secondaryGrey-900">
                                {translate("ns1:Technique.PositiveReviews")}
                              </p>
                              <p className="text-sm font-medium text-secondaryGrey-800">
                                <Trans
                                  i18nKey={
                                    "Technique.NumberOfReviews" as
                                      | never[]
                                      | (string & never[])
                                  }
                                  values={{
                                    reviews: technique.testimonials.length,
                                  }}
                                />
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="text-3xl font-bold text-secondaryGrey-900">
                                {translate("ns1:Technique.NoReviewsYet")}
                              </p>
                              <p className="text-base font-medium text-secondaryGrey-800">
                                {translate("ns1:Technique.ForThisTechnique")}
                              </p>
                            </>
                          )}
                        </div>

                        <Opinions
                          testimonials={technique.testimonials.slice(
                            0,
                            seeMoreOpinions.isOpen
                              ? technique.testimonials.length
                              : 3
                          )}
                        />

                        {technique.testimonials.length > 3 && (
                          <div className="mt-16 flex flex-row justify-center space-x-6">
                            {/* <PrimaryButton height="36px">
                      {translate("ns1:Activities.Buttons.LeaveAReview")}
                    </PrimaryButton> */}
                            <SecondaryButton
                              height="36px"
                              onClick={seeMoreOpinions.onToggle}
                            >
                              {translate(
                                `ns1:Activities.Buttons.${
                                  seeMoreOpinions.isOpen
                                    ? "SeeLessReviews"
                                    : "SeeMoreReviews"
                                }`
                              )}
                            </SecondaryButton>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {/* advice and equipment for desktop */}
                  {isDesktop && (advice || technique.materiels.length > 0) && (
                    <div className="flex w-80 flex-col space-y-6 md:space-y-8">
                      {technique.materiels.length > 0 &&
                        technique.materiels[0].id && (
                          <Materiels materiels={technique.materiels} />
                        )}
                      {advice && <Advice advice={advice} />}
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="flex h-full w-full items-center justify-center">
              <div className="loader" />
            </div>
          )}
        </div>

        {selectedTechniques &&
          updateSelectedTechniques &&
          handleTechniqueSelection && (
            <div className="sticky bottom-0 flex w-full justify-center bg-white px-6 py-4 shadow-lg">
              <div className="w-full max-w-[590px]">
                <PrimaryButton
                  height="46px"
                  width="100%"
                  outlined={selected}
                  onClick={handleTechniqueSelection}
                >
                  {translate(
                    `ns1:CreateSeance.${selected ? "Unselect" : "Select"}`
                  )}
                </PrimaryButton>
              </div>
            </div>
          )}
      </ModalContent>
    </Modal>
  );
}

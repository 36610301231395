import {
  selectPortalSessionUrl,
  selectUserInfos,
} from "domain/auth/auth.selectors";
import { HiOutlineExternalLink } from "react-icons/hi";
import { useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import { BgLightGreen500 as BgGreenDesktop } from "ui/assets/svg/account-offers-bg-desktop/BgLightGreen500";
import { BgLightGreen500 as BgGreenMobile } from "ui/assets/svg/account-offers-bg-mobile/BgLightGreen500";
import { BgPink300 as BgPinkDesktop } from "ui/assets/svg/account-offers-bg-desktop/BgPink300";
import { BgPink300 as BgPinkMobile } from "ui/assets/svg/account-offers-bg-mobile/BgPink300";
import { useWindowWidth } from "ui/utils/hooks";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { useDisclosure } from "@chakra-ui/hooks";
import { ModalSubscriptionPricing } from "ui/pages/app/account/components/ModalSubscriptionPricing";

export function SubcriptionManagement() {
  const user = useAppSelector(selectUserInfos);
  const portalSessionUrl = useAppSelector(selectPortalSessionUrl);
  const isDesktop = useWindowWidth();
  const modalSubscriptionPricing = useDisclosure();

  const handlePortalClick = () => {
    if (portalSessionUrl) {
      window.open(portalSessionUrl, "_blank");
    }
  };

  const handleOrganizationClick = () => {
    window.open("https://innizio.fr/contact-app", "_blank");
  };

  return (
    <>
      {user?.isPremium ? (
        <button
          className="flex w-full items-center justify-between p-4 py-6 disabled:opacity-30 md:p-6"
          onClick={handlePortalClick}
          disabled={!portalSessionUrl}
        >
          <span className="text-md font-medium text-secondaryGrey-800">
            {translate("ns1:Account.ManageSubscription")}
          </span>
          <HiOutlineExternalLink size={24} color="#485585" />
        </button>
      ) : (
        <div className="p-4 py-6 md:p-6">
          <div className="flex flex-col space-x-0 space-y-3 2xl:flex-row 2xl:space-x-3 2xl:space-y-0">
            <div
              className="relative w-full cursor-pointer"
              onClick={modalSubscriptionPricing.onOpen}
            >
              {isDesktop ? <BgPinkDesktop /> : <BgPinkMobile />}
              <div
                className="absolute bottom-0 left-0 right-0 top-0 flex flex-col justify-center p-6"
                style={{ margin: "auto 0" }}
              >
                <div className="mb-2 w-fit rounded-[36px] bg-pink-100 px-2 py-0.5 text-xs font-semibold text-secondaryGrey-900 sm:mb-3 2xl:mb-6">
                  {translate("ns1:Account.Individuals")}
                </div>
                <p className="text-sm font-bold text-secondaryGrey-900 md:text-base">
                  {translate("ns1:Account.SubscriptionText")}
                </p>
                <PrimaryButton className="mt-3 w-fit sm:mt-4 2xl:mt-6">
                  {translate("ns1:Button.Discover")}
                </PrimaryButton>
              </div>
            </div>
            <div
              className="relative w-full cursor-pointer"
              onClick={handleOrganizationClick}
            >
              {isDesktop ? <BgGreenDesktop /> : <BgGreenMobile />}
              <div
                className="absolute bottom-0 left-0 right-0 top-0 flex flex-col justify-center p-6"
                style={{ margin: "auto 0" }}
              >
                <div className="mb-2 w-fit rounded-[36px] bg-lightGreen-100 px-2 py-0.5 text-xs font-semibold text-secondaryGrey-900 sm:mb-3 2xl:mb-6">
                  {translate("ns1:Account.Organizations")}
                </div>
                <p className="text-sm font-bold text-secondaryGrey-900 md:text-base">
                  {translate("ns1:Account.OrganizationsText")}
                </p>
                <PrimaryButton className="mt-3 w-fit sm:mt-4 2xl:mt-6">
                  {translate("ns1:Button.Discover")}{" "}
                  <HiOutlineExternalLink
                    size={16}
                    color="#fff"
                    className="ms-1"
                  />
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      )}

      <ModalSubscriptionPricing
        isOpen={modalSubscriptionPricing.isOpen}
        onClose={modalSubscriptionPricing.onClose}
      />
    </>
  );
}

import { translate } from "ui/i18n";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { HiMail } from "react-icons/hi";
import { INInput } from "ui/pages/app/components/INInput";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Inputs } from "ui/pages/inputsType";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { loginUser } from "domain/auth/auth.actions";
import { selectIsAuthenticationLoading } from "domain/auth/auth.selectors";

export function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { register, handleSubmit, formState, setError } = useForm<Inputs>({
    mode: "onBlur",
    defaultValues: {
      password: "",
    },
  });
  const email = location.state?.email;
  const isLoading = useAppSelector(selectIsAuthenticationLoading);

  const { errors, isValid } = formState;

  useEffect(() => {
    if (!email) navigate("/auth");
  }, [email]); //eslint-disable-line

  const handleFormSubmit = async (data: Inputs) => {
    const result = await dispatch(
      loginUser({ email, password: data.password })
    ).unwrap();

    if (result.success && result.userInfos.isVerified) {
      navigate("/");
    } else if (result.success && !result.userInfos.isVerified) {
      navigate(`/tell-us-more?token=${result.userInfos.verifyToken}`);
    } else {
      setError(
        "password",
        { message: translate("ns1:Input.Errors.WrongPassword") },
        {
          shouldFocus: true,
        }
      );
    }
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-center">
        {translate("ns1:Login.WelcomeBackToInnizio")}
      </h2>
      <h4 className="mt-2 text-center md:mt-4">
        {translate("ns1:Auth.PrepareYourSeances")}
      </h4>

      <div className="mt-14 w-full max-w-[430px]">
        {/* <PrimaryButton
          icon={<FcGoogle size={24} />}
          width="100%"
          height="52px"
          outlined
          className="mt-6 md:mt-14"
        >
          {translate("ns1:Auth.ContinueWithGoogle")}
        </PrimaryButton>

        <HorizontalDivider gap={isDesktop ? "40px" : "20px"} /> */}

        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <INInput
            error={errors.email}
            label={translate("ns1:Input.Labels.Email")}
            disabled
            className="mb-2 md:mb-6"
            value={email}
          />

          <INInput
            type="password"
            error={errors.password}
            label={translate("ns1:Input.Labels.Password")}
            placeholder={translate("ns1:Input.Labels.Password")}
            className="mb-4 md:mb-8"
            register={{
              ...register("password", {
                required: translate("ns1:Input.Errors.Required"),
              }),
            }}
          />

          <PrimaryButton
            disabled={!isValid || isLoading}
            icon={<HiMail size={20} />}
            width="100%"
            height="52px"
            type="submit"
          >
            {translate("ns1:Auth.ContinueWithEmail")}
          </PrimaryButton>
        </form>

        <div className="mt-6 text-center md:mt-10">
          <button
            onClick={() => navigate("/forgot-password", { state: { email } })}
            className="text-sm font-bold text-darkGrey-700 underline md:text-base"
          >
            {translate("ns1:Auth.ForgotPassword")}
          </button>
        </div>

        <div className="mt-6 text-center text-xs leading-5 text-darkGrey-600 md:mt-10">
          <Trans
            i18nKey={"Auth.TermsOfService" as never[] | (string & never[])}
            components={[
              <Link
                className="hover:underline"
                to="/conditions-of-use"
                target="_blank"
                rel="noopener noreferrer"
              />,
              <Link
                className="hover:underline"
                to="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              />,
            ]}
          />
        </div>
      </div>
    </div>
  );
}

import {
  ActivitiesPageFilters,
  DurationFilter,
} from "domain/entities/activities.entities";

export const getFiltersCount = (
  filters: ActivitiesPageFilters & {
    [key: string]: string[] | string | boolean;
  },
  duration?: DurationFilter,
  minDuration?: number,
  maxDuration?: number
) => {
  let count = 0;
  if (filters.contexts?.length > 0) count++;
  if (filters.types?.length > 0) count++;
  if (filters.objectives?.length > 0) count++;
  if (filters.participants?.length > 0) count++;
  if (filters.modalities?.length > 0) count++;
  if (filters.selectedDuration?.length > 0) count++;
  if (
    duration &&
    minDuration &&
    maxDuration &&
    (duration.$gte > minDuration || duration.$lte < maxDuration)
  )
    count++;
  return count;
};
